<template>
  <!-- 运行监管 - 意见反馈 -->
  <div class="pageContol generateStatementAccount">
    <div class="framePage">
      <!-- 面包屑 -->
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">结算管理</a>
          <i>></i>
          <a href="javascript:;" @click="$router.back()">结算单管理</a>
          <i>></i>
          <a href="javascript:;" class="cur-a">生成结算单</a>
        </span>
      </div>
      <!-- 页面内容 -->
      <div class="framePage-body">
        <el-form
          ref="seachData"
          :model="seachData"
          label-width="120px"
          label-position="right"
          :rules="rules"
          class="demo-ruleForm"
          ><div class="operations_1">
            <el-select
              v-model="value"
              placeholder="选择查看合同"
              @change="seeContract"
              clearable
              @clear="clear"
            >
              <el-option
                v-for="item in seeContracts"
                :key="item.pdfUrl"
                :label="item.contractName"
                :value="item.pdfUrl"
                @click.native="insertField(item.pdfUrl)"
              ></el-option>
            </el-select>
            <el-button
              class="bgc-bv"
              round
              size="small"
              @click="lookProjectDetail()"
              >查看班级明细</el-button
            >
          </div>
          <h2>选择结算单类型</h2>
          <el-form-item label="结算单类型" class="serialNumberType" required>
            <div style="display:flex;align-items:center">
              <el-form-item class="serialNumberType" prop="serialNumberType">
                <el-select
                  v-model="seachData.serialNumberType"
                  clearable
                  size="small"
                  :disabled="$route.query.billId"
                  placeholder="请选择结算单类型"
                  class="szwidth"
                >
                  <el-option
                    v-for="item in settlementTypes"
                    :key="item.label"
                    :label="item.label"
                    :value="item.label"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item class="serialNumberType" prop="serialNumberYear">
                <el-date-picker
                  v-model="seachData.serialNumberYear"
                  type="year"
                  prefix-icon=""
                  size="small"
                  placeholder="选择年份"
                  class="szwidth"
                  format="yyyy"
                  value-format="yyyy"
                  @change="checkNum"
                >
                </el-date-picker>
              </el-form-item>
              <el-form-item class="serialNumberType" prop="serialNumberNum">
                <el-input
                  v-model="seachData.serialNumberNum"
                  placeholder="请输入4位单号"
                  size="small"
                  maxlength="4"
                  minlength="4"
                  @blur="checkNum"
                  onkeyup="value=value.replace(/[^\d]/g,'')"
                  style="margin-left:10px"
                ></el-input>
              </el-form-item>
              <el-form-item
                prop="serialbillSeq"
                class="serialNumberType"
                v-if="$route.query.stu == 'change' || $route.query.billSeq"
              >
                <!-- <el-input
                  v-model="seachData.serialbillSeq"
                  placeholder="请输入变更序号 "
                  size="small"
                  @blur="checkNum"
                  onkeyup="value=value.replace(/[^\d]/g,'')"
                  style="margin-left:20px"
                ></el-input> -->
                <el-select v-model="seachData.serialbillSeq" placeholder="请选择变更序号"  @change="checkNum" class="szwidth"  style="margin-left:20px">
                  <el-option
                    v-for="item in billSeqList"
                    :key="item.label"
                    :label="item.label"
                    :value="item.label"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </div>
          </el-form-item>
          <el-divider></el-divider>
          <h2>设置结算标准</h2>
          <h3>结算标准模式</h3>
          <el-form-item label="结算标准" prop="Settlementstandardmode">
            <el-radio-group
              v-model="seachData.Settlementstandardmode"
              size="small"
              @change="Settlementstandardmodechange"
            >
              <el-radio
                v-for="item in SettlementstandardmodeList"
                :label="item.value"
                :key="item.value"
                >{{ item.label }}</el-radio
              >
            </el-radio-group>
          </el-form-item>
          <template v-if="seachData.Settlementstandardmode == '20'">
            <h3>平台服务费</h3>
            <div class="shell">
              <el-form-item label="收费方式" prop="platformPayType">
                <el-radio-group
                  v-model="seachData.platformPayType"
                  size="small"
                >
                  <el-radio
                    v-for="item in platformPayTypes"
                    :label="item.value"
                    :key="item.value"
                    >{{ item.label }}</el-radio
                  >
                </el-radio-group>
              </el-form-item>
              <el-form-item
                label="收费比例："
                v-if="seachData.platformPayType == 20"
                prop="platformPayRatio"
              >
                <el-table
                  :data="seachData.platformPayChargeNumberFee"
                  border
                  class="tb-edit"
                  :highlight-current-row="true"
                  @cell-dblclick="dblclick2"
                  style="width: 100%"
                >
                  <el-table-column
                    prop="projectName"
                    label="班级名称"
                    show-overflow-tooltip
                    minWidth="230"
                  ></el-table-column>
                  <el-table-column
                    prop="userPayCount"
                    label="班级人数"
                    show-overflow-tooltip
                  ></el-table-column>
                  <el-table-column
                    prop="platformPayRatio"
                    label="收费比例(%)"
                    show-overflow-tooltip
                    minWidth="100"
                  >
                    <template slot-scope="scope">
                      <el-input
                        :readonly="scope.row.readonly"
                        size="small"
                        v-model="scope.row.platformPayRatio"
                        placeholder="请输入收费比例"
                        @keyup.native="
                          scope.row.platformPayRatio = zF.oninput(
                            scope.row.platformPayRatio,
                            2
                          )
                        "
                      ></el-input>
                      <span>{{ scope.row.platformPayRatio || "" }}</span>
                    </template>
                  </el-table-column>

                  <Empty slot="empty" />
                </el-table>
              </el-form-item>
              <el-form-item label="每人收费额度：" v-else>
                <el-table
                  :data="seachData.platformPayChargeNumberFee"
                  border
                  class="tb-edit"
                  :highlight-current-row="true"
                  @cell-dblclick="dblclick2"
                  style="width: 100%"
                >
                  <el-table-column
                    prop="projectName"
                    label="班级名称"
                    show-overflow-tooltip
                    minWidth="230"
                  ></el-table-column>
                  <el-table-column
                    prop="userPayCount"
                    label="班级人数"
                    show-overflow-tooltip
                  ></el-table-column>
                  <el-table-column
                    prop="platformPayMoney"
                    label="每人收费金额"
                    show-overflow-tooltip
                    minWidth="140"
                  >
                    <template slot-scope="scope">
                      <el-input
                        :readonly="scope.row.readonly"
                        @blur="blur(scope.row, scope.$index)"
                        size="small"
                        v-model="scope.row.platformPayMoney"
                        placeholder="请输入每人收费金额"
                        @keyup.native="
                          scope.row.platformPayMoney = zF.oninput(
                            scope.row.platformPayMoney,
                            2
                          )
                        "
                      ></el-input>
                      <span>{{ scope.row.platformPayMoney || "" }}</span>
                    </template>
                  </el-table-column>
                  <Empty slot="empty" />
                </el-table>
              </el-form-item>
            </div>
          </template>
          <template v-else style="display: flex;flex-direction: column;">
            <h3>
              平台技术服务费
            </h3>
            <div class="shell">
              <el-form-item label="收费方式" prop="techniquePayType">
                <el-radio-group
                  v-model="seachData.techniquePayType"
                  size="small"
                  @change="change"
                >
                  <el-radio
                    v-for="item in technologyChargeTypes"
                    :label="item.value"
                    :key="item.value"
                    >{{ item.label }}</el-radio
                  >
                </el-radio-group>
              </el-form-item>
              <el-form-item
                label="收费比例："
                v-if="seachData.techniquePayType == 20"
                prop="techniquePayRatio"
              >
                <el-table
                  :data="seachData.technologyChargeNumberFee"
                  border
                  class="tb-edit"
                  :highlight-current-row="true"
                  @cell-dblclick="dblclick0"
                  style="width: 100%"
                >
                  <el-table-column
                    prop="projectName"
                    label="班级名称"
                    show-overflow-tooltip
                    minWidth="230"
                  ></el-table-column>
                  <el-table-column
                    prop="userPayCount"
                    label="班级人数"
                    show-overflow-tooltip
                  ></el-table-column>
                  <el-table-column
                    prop="techniquePayRatio"
                    label="收费比例(%)"
                    show-overflow-tooltip
                    minWidth="100"
                  >
                    <template slot-scope="scope">
                      <el-input
                        :readonly="scope.row.readonly"
                        size="small"
                        v-model="scope.row.techniquePayRatio"
                        placeholder="请输入收费比例"
                        @keyup.native="
                          scope.row.techniquePayRatio = zF.oninput(
                            scope.row.techniquePayRatio,
                            2
                          )
                        "
                      ></el-input>
                      <span>{{ scope.row.techniquePayRatio || "" }}</span>
                    </template>
                  </el-table-column>
                  <Empty slot="empty" />
                </el-table>
              </el-form-item>
              <el-form-item label="每人收费额度：" v-else>
                <el-table
                  :data="seachData.technologyChargeNumberFee"
                  border
                  class="tb-edit"
                  :highlight-current-row="true"
                  @cell-dblclick="dblclick0"
                  style="width: 100%"
                >
                  <el-table-column
                    prop="projectName"
                    label="班级名称"
                    show-overflow-tooltip
                    minWidth="230"
                  ></el-table-column>
                  <el-table-column
                    prop="userPayCount"
                    label="班级人数"
                    show-overflow-tooltip
                  ></el-table-column>
                  <el-table-column
                    prop="techniquePayMoney"
                    label="每人收费金额"
                    show-overflow-tooltip
                    minWidth="140"
                  >
                    <template slot-scope="scope">
                      <el-input
                        :readonly="scope.row.readonly"
                        @blur="blur(scope.row, scope.$index)"
                        size="small"
                        v-model="scope.row.techniquePayMoney"
                        placeholder="请输入每人收费金额"
                        @keyup.native="
                          scope.row.techniquePayMoney = zF.oninput(
                            scope.row.techniquePayMoney,
                            2
                          )
                        "
                      ></el-input>
                      <span>{{ scope.row.techniquePayMoney || "" }}</span>
                    </template>
                  </el-table-column>
                  <Empty slot="empty" />
                </el-table>
              </el-form-item>
            </div>
            <el-divider
              v-if="seachData.Settlementstandardmode == '10'"
            ></el-divider>
            <h3>
              平台课程服务费
            </h3>
            <div class="shell">
              <el-form-item label="收费方式">
                <el-radio-group
                  v-model="seachData.coursePayType"
                  size="small"
                  @change="change2"
                >
                  <el-radio
                    v-for="item in curriculumChargeTypes"
                    :label="item.value"
                    :key="item.value"
                    >{{ item.label }}</el-radio
                  >
                </el-radio-group>
              </el-form-item>
              <el-form-item
                label="收费比例："
                v-if="seachData.coursePayType == 20"
                prop="coursePayRatio"
              >
                <el-table
                  :data="seachData.coursePayRatio"
                  border
                  class="tb-edit"
                  :highlight-current-row="true"
                  @cell-dblclick="dblclick1"
                  style="width: 100%"
                >
                  <el-table-column
                    prop="projectName"
                    label="班级名称"
                    show-overflow-tooltip
                    minWidth="230"
                  ></el-table-column>
                  <el-table-column
                    prop="userPayCount"
                    label="班级人数"
                    show-overflow-tooltip
                  ></el-table-column>
                  <el-table-column
                    prop="coursePayRatio"
                    label="收费比例(%)"
                    show-overflow-tooltip
                    minWidth="100"
                  >
                    <template slot-scope="scope">
                      <el-input
                        :readonly="scope.row.readonly"
                        v-model="scope.row.coursePayRatio"
                        placeholder="请输入收费比例"
                        @keyup.native="
                          scope.row.coursePayRatio = zF.oninput(
                            scope.row.coursePayRatio,
                            2
                          )
                        "
                      ></el-input>
                      <span>{{ scope.row.coursePayRatio || "" }}</span>
                    </template>
                  </el-table-column>
                  <Empty slot="empty" />
                </el-table>
              </el-form-item>
              <el-form-item label="每人收费额度" v-else>
                <el-table
                  :data="seachData.curriculumChargeNumberFee"
                  border
                  class="tb-edit"
                  :highlight-current-row="true"
                  @cell-dblclick="dblclick1"
                  style="width: 100%"
                >
                  <el-table-column
                    prop="projectName"
                    label="班级名称"
                    show-overflow-tooltip
                    minWidth="230"
                  ></el-table-column>
                  <el-table-column
                    prop="userPayCount"
                    label="班级人数"
                    show-overflow-tooltip
                  ></el-table-column>
                  <el-table-column
                    prop="coursePayMoney"
                    label="每人收费金额"
                    show-overflow-tooltip
                    minWidth="140"
                  >
                    <template slot-scope="scope">
                      <el-input
                        size="small"
                        :readonly="scope.row.readonly"
                        @blur="blur(scope.row, scope.$index)"
                        v-model="scope.row.coursePayMoney"
                        placeholder="请输入每人收费金额"
                        @keyup.native="
                          scope.row.coursePayMoney = zF.oninput(
                            scope.row.coursePayMoney,
                            2
                          )
                        "
                      ></el-input>
                      <span>{{ scope.row.coursePayMoney || "" }}</span>
                    </template>
                  </el-table-column>
                  <Empty slot="empty" />
                </el-table>
              </el-form-item>
            </div>
            <el-divider
              v-if="seachData.Settlementstandardmode == '10'"
            ></el-divider>
          </template>
          <el-divider
            v-if="seachData.Settlementstandardmode == '20'"
          ></el-divider>
          <el-form-item label="补贴标准">
            <el-table
              :data="seachData.subsidyStandard"
              ref="singleTable"
              border
              class="tb-edit"
              :highlight-current-row="true"
              @cell-click="dblclick"
              style="width: 100%"
            >
              <el-table-column
                prop="projectName"
                label="班级名称"
                show-overflow-tooltip
                minWidth="230"
              ></el-table-column>
              <el-table-column
                prop="subsidyAmount"
                label="补贴标准（元）"
                show-overflow-tooltip
                minWidth="120"
              >
                <template slot-scope="scope">
                  <el-input
                    size="small"
                    :readonly="scope.row.readonly"
                    @blur="blur(scope.row, scope.$index)"
                    v-model="scope.row.subsidyAmount"
                    placeholder="请输入补贴标准"
                    @keyup.native="
                      scope.row.subsidyAmount = zF.oninput(
                        scope.row.subsidyAmount,
                        2
                      )
                    "
                  ></el-input>
                  <!-- <span>{{ scope.row.subsidyAmount || "" }}</span> -->
                </template>
              </el-table-column>
              <el-table-column
                prop="subsidyCategory"
                label="类别"
                show-overflow-tooltip
                minWidth="100"
              >
                <template slot-scope="scope">
                  <el-select
                    v-model="scope.row.subsidyCategory"
                    placeholder="请选择"
                    @visible-change="changes(scope.$index, val)"
                  >
                    <el-option
                      v-for="(item, index) in subsidyCategoryList"
                      :key="index"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </template>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </el-form-item>
          <el-divider></el-divider>
          <el-form-item label="其他设置">
            <el-checkbox-group
              v-model="seachData.otherSettings"
              v-if="seachData.Settlementstandardmode == '10'"
            >
              <el-checkbox
                v-for="item in otherSettingss"
                :label="item.value"
                :key="item.value"
                >{{ item.name }}</el-checkbox
              >
            </el-checkbox-group>
            <el-checkbox-group v-model="seachData.otherSettings" v-else>
              <el-checkbox
                v-for="item in otherSettingstwo"
                :label="item.value"
                :key="item.value"
                >{{ item.name }}</el-checkbox
              >
            </el-checkbox-group>
          </el-form-item>
          <el-form-item label="收费标准" prop="charges">
            <el-input
              type="textarea"
              maxlength="100"
              show-word-limit
              v-model="seachData.charges"
              placeholder="请输入收费标准"
            ></el-input>
          </el-form-item>
          <el-divider></el-divider>
          <h2>收付款账号信息</h2>
          <el-form-item label="付款单位：" prop="payerName">
            <el-input
              v-model="seachData.payerName"
              maxlength="30"
              size="small"
              placeholder="请输入付款单位"
            ></el-input>
          </el-form-item>
          <el-form-item label="收款单位：" prop="payeeName">
            <el-input
              v-model="seachData.payeeName"
              maxlength="30"
              size="small"
              placeholder="请输入收款单位"
            ></el-input>
          </el-form-item>
          <el-form-item label="开户银行：" prop="payeeBank">
            <el-input
              v-model="seachData.payeeBank"
              maxlength="29"
              size="small"
              placeholder="请输入开户银行"
            ></el-input>
          </el-form-item>
          <el-form-item label="银行账号：" prop="payeeAccount">
            <el-input
              v-model="seachData.payeeAccount"
              maxlength="30"
              size="small"
              placeholder="请输入银行账号"
            ></el-input>
          </el-form-item>
          <el-form-item class="operations_2">
            <el-button
              class="bgc-bv"
              round
              size="small"
              @click="watchSee('seachData')"
              >预览结算单</el-button
            >
            <el-button class="bgc-bv" round size="small" @click="cancle"
              >取消</el-button
            >
            <el-button
              class="bgc-bv"
              round
              size="small"
              @click="sure('seachData')"
              >确认</el-button
            >
          </el-form-item>
        </el-form>
      </div>
    </div>
    <!-- 查看合同的 loading -->
    <el-dialog
      :title="lookhtTitle"
      :visible.sync="dialogVisible1"
      width="50%"
      @close="closee"
    >
      <div id="pdf-cert" style="height: 600px"></div>
    </el-dialog>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue"; // 引入表格无数据提示 - 组件
import pdf from "pdfobject";
export default {
  name: "evaluate/generateStatementAccount",
  components: {
    Empty,
  },
  data() {
    return {
      // 页面数据
      seachData: {
        Settlementstandardmode: "10",
        // 反馈类型

        serialNumberType: "",
        serialNumberYear: "",
        serialNumberNum: "",
        serialbillSeq: "",
        // 平台技术服务费 - 收费方式
        techniquePayType: "20",
        // 平台技术服务费 - 收费方式 - 比例收费
        techniquePayRatio: [],
        // 平台技术服务费 - 收费方式 - 人数收费
        technologyChargeNumberFee: [],
        // 平台课程服务费 - 收费方式
        coursePayType: "20",
        // 平台课程服务费 - 收费方式 - 比例收费
        coursePayRatio: [],
        // 平台课程服务费 - 收费方式 - 人数收费
        curriculumChargeNumberFee: [],
        // 平台服务费 - 收费方式
        platformPayType: "20",
        // 平台服务费 - 收费方式 - 比例收费
        platformPayRatio: [],
        // 平台服务费 - 收费方式 - 人数收费
        platformPayChargeNumberFee: [],
        // 补贴标准
        subsidyStandard: [],
        // 其他设置
        otherSettings: [],
        // 付款单位
        payerName: "",
        // 收款单位
        payeeName: "北京晟融信息技术有限公司",
        // 开户银行
        payeeBank: "中国民生银行股份有限公司北京苏州街支行",
        // 银行账号
        payeeAccount: "154322354",
        // 班级列表数据
        projects: [],
        charges: "",
      },
      // 结算类型 - 下拉数据
      settlementTypes: [
        {
          label: "T1",
        },
        {
          label: "T2",
        },
        {
          label: "T3",
        },
        {
          label: "T4",
        },
        {
          label: "T5",
        },
      ],
      billSeqList:[
        {
          label: "1",
        },
        {
          label: "2",
        },
        {
          label: "3",
        },
        {
          label: "4",
        },
        {
          label: "5",
        },
        {
          label: "6",
        },
        {
          label: "7",
        },
        {
          label: "8",
        },
        {
          label: "9",
        },
        {
          label: "10",
        }
      ],
      /* 结算标准模式 */
      SettlementstandardmodeList: [
        {
          label: "技术/课程费用分开",
          value: "10",
        },
        {
          label: "技术/课程费用合计",
          value: "20",
        },
      ],
      // 平台技术服务费 - 收费方式 - 单选数据
      technologyChargeTypes: [
        {
          label: "按比例收费",
          value: "20",
        },
        {
          label: "按人数收费",
          value: "30",
        },
      ],
      // 平台课程服务费 - 收费方式 - 单选数据
      curriculumChargeTypes: [
        {
          label: "按比例收费",
          value: "20",
        },
        {
          label: "按人数收费",
          value: "30",
        },
      ],
      // 平台服务费 - 收费方式 - 单选数据
      platformPayTypes: [
        {
          label: "按比例收费",
          value: "20",
        },
        {
          label: "按人数收费",
          value: "30",
        },
      ],
      // 其他设置 - 多选框数据
      otherSettingss: [
        {
          name: "公共课收费",
          value: "0",
        },
        {
          name: "显示完成80%学员人数",
          value: "1",
        },
      ],
      otherSettingstwo: [
        {
          name: "显示完成80%学员人数",
          value: "1",
        },
      ],
      // 选择查看合同 - 下拉数据
      seeContracts: [],
      // 查看班级明细 - loading
      dialogVisible1: false,
      // 预览结算单 - loading
      dialogVisible2: false,
      // 数据校验
      rules: {
        serialNumberType: [
          { required: true, message: "请选择结算单类型", trigger: "change" },
        ],
        serialNumberYear: [
          { required: true, message: "请选择年份", trigger: "blur" },
        ],
        serialNumberNum: [
          { required: true,validator: this.validatePass8 ,trigger: "blur" },
        ],
        serialbillSeq: [
          { required: true, message: "请输入变更序号", trigger: "blur" },
        ],
        techniquePayRatio: [
          {
            required: true,
            message: "请输入平台技术服务费收费比例",
            trigger: "change",
          },
          { required: true, validator: this.validatePass5, trigger: "blur" },
        ],
        coursePayRatio: [
          {
            required: true,
            message: "请输入平台课程服务费收费比例",
            trigger: "change",
          },
          { required: true, validator: this.validatePass6, trigger: "blur" },
        ],
        platformPayRatio: [
          {
            required: true,
            message: "请输入平台收费比例",
            trigger: "blur",
          },
          { required: true, validator: this.validatePass7, trigger: "blur" },
        ],
        charges: [
          { required: true, message: "请输入收费标准", trigger: "blur" },
        ],
        payerName: [
          { required: true, message: "请输入付款单位", trigger: "blur" },
        ],
        payeeName: [
          { required: true, message: "请输入收款单位", trigger: "blur" },
        ],
        payeeBank: [
          { required: true, message: "请输入开户银行", trigger: "blur" },
        ],
        payeeAccount: [
          { required: true, message: "请输入银行账号", trigger: "blur" },
        ],
      },
      // 初始化 - 获取后台给的班级数据
      getCreatData: [],
      lookhtTitle: "",
      billId: "",
      compId: "",
      readonly: true,
      subsidyCategoryList: [],
    };
  },
  // watch: {
  //   // 监听平台服务费类型：按人数收费时-收费比例不做校验
  //   seachData: {
  //     handler: function() {
  //       for(var i=0 ; i<this.seachData.subsidyStandard.length; i++) {
  //               // this.seachData.subsidyStandard[i].subsidyCategory
  //               console.log(this.seachData.subsidyStandard[i].subsidyCategory);
  //       }
  //       // this.seachData.techniquePayType == 30
  //       // if (this.seachData.techniquePayType == 30) {
  //       //   this.$refs.seachData.clearValidate("techniquePayRatio");
  //       // } else {
  //       // }
  //     },
  //     deep:true
  //   }
  // },
  mounted() {},
  computed: {},
  created() {
    this.getContract();
    this.getsubsidyCategoryList();
    if (this.$route.query.infos) {
      this.getproject();
      this.seachData.payerName = JSON.parse(this.$route.query.infos).compName;
    }
    if (this.$route.query.billId) {
      this.getEchos();
    }
  },
  methods: {
    checkNum() {
      let parmar = {
        serialNumberType: this.seachData.serialNumberType,
        year: this.seachData.serialNumberYear,
        billNum: this.seachData.serialNumberNum,
        seq: this.seachData.serialbillSeq,
      };
      if (this.seachData.serialNumberNum.length == 4 && this.seachData.serialNumberNum != '0000') {
        this.$post("/biz/settlement/bill/checkNo", parmar)
          .then((ret) => {
            console.log(ret.data);
            if (ret.status == "0") {
              if(ret.data == false) {
                 this.$message({
                message: ret.message,
                type: "error",
              });
              } else {
                 this.$message({
                message: ret.message,
                type: "success",
              });
              }
             
            }
          })
          .catch((err) => {});
      }
    },
    closee() {
      this.$nextTick(function() {
        this.$forceUpdate();
      });
    },
    // //合并单元格方法
    // objectSpanMethod1({ row, column, rowIndex, columnIndex }) {
    //   if (columnIndex === 2) {
    //     return {
    //       rowspan: this.seachData.coursePayRatio.length,
    //       colspan: 1,
    //     };
    //   }
    // },
    // objectSpanMethod2({ row, column, rowIndex, columnIndex }) {
    //   if (columnIndex === 2) {
    //     return {
    //       rowspan: this.seachData.techniquePayRatio.length,
    //       colspan: 1,
    //     };
    //   }
    // },
    // objectSpanMethod3({ row, column, rowIndex, columnIndex }) {
    //   if (columnIndex === 2) {
    //     return {
    //       rowspan: this.seachData.platformPayRatio.length,
    //       colspan: 1,
    //     };
    //   }
    // },
    validatePass5(rule, value, callback) {
      let reg = /^(0|[1-9][0-9]?|100)(\.\d{1,50})?$/;
      if (this.seachData.techniquePayRatio[0].techniquePayRatio == "") {
        callback(new Error("请输入平台技术服务费收费比例"));
      } else if (
        !reg.test(this.seachData.techniquePayRatio[0].techniquePayRatio)
      ) {
        callback(new Error("平台技术服务费收费比例不能超过100以外"));
      } else if (this.seachData.techniquePayRatio[0].techniquePayRatio > 100) {
        callback(new Error("平台技术服务费收费比例不能超过100以外"));
      } else {
        callback();
      }
    },
    validatePass6(rule, value, callback) {
      let reg = /^(0|[1-9][0-9]?|100)(\.\d{1,50})?$/;
      console.log(this.seachData.coursePayRatio[0].coursePayRatio);
      if (this.seachData.coursePayRatio[0].coursePayRatio == "") {
        callback(new Error("请输入平台课程服务费收费比例"));
      } else if (!reg.test(this.seachData.coursePayRatio[0].coursePayRatio)) {
        callback(new Error("平台课程服务费收费比例支持不能超过100以外"));
      } else if (this.seachData.coursePayRatio[0].coursePayRatio > 100) {
        callback(new Error("平台课程服务费收费比例支持不能超过100以外"));
      } else {
        callback();
      }
    },
    validatePass7(rule, value, callback) {
      let reg = /^(0|[1-9][0-9]?|100)(\.\d{1,50})?$/;
      console.log(this.seachData.platformPayRatio[0].platformPayRatio);
      if (this.seachData.platformPayRatio[0].platformPayRatio == "") {
        callback(new Error("请输入平台收费比例"));
      } else if (
        !reg.test(this.seachData.platformPayRatio[0].platformPayRatio)
      ) {
        callback(new Error("平台收费比例支持不能超过100以外"));
      } else if (this.seachData.platformPayRatio[0].platformPayRatio > 100) {
        callback(new Error("平台收费比例支持不能超过100以外"));
      } else {
        callback();
      }
    },
    validatePass8(rule, value, callback) {
      if (value.length<4) {
        callback(new Error("请输入4位单号"));
      } else if(value == '0000'){
         callback(new Error("单号不能为0000"));
      } else {
         callback();
      }
    },
    // 查看合同
    seeContract(val) {
      this.dialogVisible1 = true;
      this.lookhtTitle = "查看合同";
      this.$nextTick(() => {
        pdf.embed(val, "#pdf-cert");
      });
    },
    insertField(val) {
      this.dialogVisible1 = true;
      this.$nextTick(() => {
        pdf.embed(val, "#pdf-cert");
      });
    },
    clear() {
      this.dialogVisible1 = false;
    },
    change(val) {
      this.$forceUpdate();
      if (val == "30") {
        this.$nextTick(function() {
          this.$refs.seachData.clearValidate("");
          for (let i = 0; i < this.getCreatData.length; i++) {
            this.seachData.technologyChargeNumberFee[i] = {
              ...this.seachData.technologyChargeNumberFee[i],
              readonly: true,
            };
          }
        });
      }
    },
    change2(val) {
      this.$forceUpdate();
      if (val == "30") {
        this.$nextTick(function() {
          this.$refs.seachData.clearValidate("");
          for (let i = 0; i < this.getCreatData.length; i++) {
            this.seachData.curriculumChargeNumberFee[i] = {
              ...this.seachData.curriculumChargeNumberFee[i],
              readonly: true,
            };
          }
        });
      }
    },
    change3(val) {
      console.log(val);
      if (val == "30") {
        this.$nextTick(function() {
          this.$refs.seachData.clearValidate("");
        });
      }
    },
    Settlementstandardmodechange(val) {
      if (val == "10") {
        this.seachData.techniquePayType = "20";
        this.seachData.coursePayType = "20";
        // this.$nextTick(function() {
        //   this.$refs.seachData.clearValidate("");
        // });
      }
    },
    getContract() {
      this.$post("/biz/settlement/bill/contractlist", {
        compId:
          this.$route.query.compId ||
          JSON.parse(this.$route.query.infos).compId,
      })
        .then((ret) => {
          this.seeContracts = ret.data.list;
        })
        .catch((err) => {
          return;
        });
    },
    /* 查看班级详细 */
    lookProjectDetail() {
      let ids = [];
      this.getCreatData.filter((item) => {
        console.log(item.projectId);
        ids.push(item.projectId);
      });
      let paramr = {
        projects: ids,
        compId:
          this.$route.query.compId ||
          JSON.parse(this.$route.query.infos).compId,
      };
      if (this.$route.query.billId) {
        paramr.billId = this.$route.query.billId;
      }
      this.$post("/biz/settlement/bill/choose/details", paramr)
        .then((ret) => {
          this.dialogVisible1 = true;
          this.lookhtTitle = "查看班级明细";
          this.$nextTick(() => {
            pdf.embed(ret.data, "#pdf-cert");
          });
        })
        .catch((err) => {
          return;
        });
    },
    getsubsidyCategoryList() {
      const subsidyCategorylist = this.$setDictionary(
        "SUBSIDYCATEGORY",
        "list"
      );
      const list = [];
      for (const key in subsidyCategorylist) {
        list.push({
          value: key,
          label: subsidyCategorylist[key],
        });
      }
      this.subsidyCategoryList = list;
    },
    // 初始化 - 获取班级数据
    getproject() {
      this.$post("/biz/settlement/bill/choose/project", {
        projectIds: JSON.parse(this.$route.query.infos).projectIdArr,
      })
        .then((ret) => {
          let retData = ret.data.projects;
          this.getCreatData = retData;
          // 课程
          this.seachData.curriculumChargeNumberFee = retData;
          // 补贴
          this.seachData.subsidyStandard = retData;
          // 技术
          this.seachData.technologyChargeNumberFee = retData;
          //   //平台收费
          this.seachData.platformPayChargeNumberFee = retData;
          // 课程-比例
          for (let i in retData) {
            retData[i].coursePayRatio = "";
            retData[i].techniquePayRatio = "";
            retData[i].platformPayRatio = "";
          }
          this.seachData.coursePayRatio = retData;
          // 技术-比例
          this.seachData.techniquePayRatio = retData;
          this.seachData.techniquePayRatio = retData;
          this.seachData.platformPayRatio = retData;
          for (let i = 0; i < this.getCreatData.length; i++) {
            this.seachData.technologyChargeNumberFee[i] = {
              ...this.seachData.technologyChargeNumberFee[i],
              techniquePayMoney: "",
              readonly: true,
            };
            this.seachData.curriculumChargeNumberFee[i] = {
              ...this.seachData.curriculumChargeNumberFee[i],
              coursePayMoney: "",
              readonly: true,
            };
            this.seachData.subsidyStandard[i] = {
              ...this.seachData.subsidyStandard[i],
              subsidyAmount:
                this.seachData.subsidyStandard[i].subsidyAmount || "",
              subsidyCategory:
                this.seachData.subsidyStandard[i].subsidyCategory || "",
              readonly: true,
            };
            this.seachData.platformPayChargeNumberFee[i] = {
              ...this.seachData.platformPayChargeNumberFee[i],
              platformPayMoney: "",
              readonly: true,
            };
          }
          console.log(this.seachData.subsidyStandard);
        })
        .catch((err) => {
          return;
        });
    },
    /* 编辑回显数据 */
    getEchos() {
      this.$post("/biz/settlement/bill/show", {
        billId: this.$route.query.billId,
      })
        .then((ret) => {
          let retData = ret.data;
          // this.getCreatData = retData.projects,
          // this.compId = retData.compId;
          this.getCreatData = retData.projects;
          //   // 课程
          this.seachData.curriculumChargeNumberFee = retData.projects;
          //   // 补贴
          this.seachData.subsidyStandard = retData.projects;
          //平台合并收费
          this.seachData.platformPayChargeNumberFee = retData.projects;
          //   // 技术
          this.seachData.technologyChargeNumberFee = retData.projects;
          for (let i = 0; i < this.getCreatData.length; i++) {
            this.seachData.technologyChargeNumberFee[i] = {
              ...this.seachData.technologyChargeNumberFee[i],
              readonly: true,
            };
            this.seachData.curriculumChargeNumberFee[i] = {
              ...this.seachData.curriculumChargeNumberFee[i],
              readonly: true,
            };
            this.seachData.platformPayChargeNumberFee[i] = {
              ...this.seachData.platformPayChargeNumberFee[i],
              readonly: true,
            };
            this.seachData.subsidyStandard[i] = {
              ...this.seachData.subsidyStandard[i],
              readonly: false,
            };
          }
          ;
          this.seachData.techniquePayType =
            retData.projects[0].techniquePayType;
          this.seachData.coursePayType = retData.projects[0].coursePayType;
          this.seachData.platformPayType = retData.projects[0].platformPayType;

          this.seachData.techniquePayRatio = retData.projects;
          this.seachData.coursePayRatio = retData.projects;
          this.seachData.platformPayRatio = retData.projects;
          if (retData.isPublicClassPay) {
            this.seachData.otherSettings.push("0");
          }
          if (retData.isShowEightyPercentage) {
            this.seachData.otherSettings.push("1");
          }
          this.seachData.serialNumberType = retData.serialNumberType; // 结算单类型
          this.seachData.serialNumberNum = retData.billNum; // 结算单类型
          this.seachData.serialNumberYear = retData.year; // 结算单类型
          this.seachData.serialbillSeq = retData.billSeq; // 结算单类型
          this.seachData.charges = retData.charges; //收费标准
          this.seachData.payerName = retData.payerName; //付款单位名称
          this.seachData.payeeName = retData.payeeName; //收款单位名称
          this.seachData.payeeBank = retData.payeeBank; //收款单位开户银行
          this.seachData.payeeAccount = retData.payeeAccount; //收款单位银行账号
          this.seachData.Settlementstandardmode = retData.settlementPattern; //收款单位银行账号
          this.$forceUpdate();
        })
        .catch((err) => {
          return;
        });
    },
    /* 取消 */
    cancle() {
      this.$router.back();
    },
    // 确定 - 生成结算单按钮
    sure(formName) {
      let parmar = {
        settlementPattern: this.seachData.Settlementstandardmode,
        serialNumberType: this.seachData.serialNumberType,
        charges: this.seachData.charges, //收费标准描述
        compId:
          this.$route.query.compId ||
          JSON.parse(this.$route.query.infos).compId,
        payeeAccount: this.seachData.payeeAccount, //收款单位-银行账号
        payeeBank: this.seachData.payeeBank, //收款单位-开户银行
        payeeName: this.seachData.payeeName, //收款单位名称
        payerName: this.seachData.payerName, // 付款单位名称
        isPublicClassPay: this.seachData.otherSettings.includes("0"),
        isShowEightyPercentage: this.seachData.otherSettings.includes("1"),
        year: this.seachData.serialNumberYear,
        billNum: this.seachData.serialNumberNum,
        seq: this.seachData.serialbillSeq,
      };
      if (this.$route.query.billId) {
        parmar.billId = this.$route.query.billId;
      }

      // 平台合并收费
      if (this.seachData.Settlementstandardmode == "20") {
        if (this.seachData.platformPayType == 30) {
          for (let i in this.getCreatData) {
            this.getCreatData[
              i
            ].platformPayType = this.seachData.platformPayType; // 收费方式
            this.getCreatData[i].platformPayMoney =
              this.seachData.platformPayChargeNumberFee[i].platformPayMoney ||
              ""; // 收费金额
            this.getCreatData[i].platformPayRatio = ""; // 收费比例
          }
        } else {
          for (let i in this.getCreatData) {
            this.getCreatData[
              i
            ].platformPayType = this.seachData.platformPayType;
            this.getCreatData[i].platformPayMoney = "";
            this.getCreatData[
              i
            ].platformPayRatio = this.seachData.platformPayRatio[0].platformPayRatio;
          }
        }
      } else {
        // 技术收费
        if (this.seachData.techniquePayType == 30) {
          for (let i in this.getCreatData) {
            this.getCreatData[
              i
            ].techniquePayType = this.seachData.techniquePayType; // 收费方式
            this.getCreatData[
              i
            ].techniquePayMoney = this.seachData.technologyChargeNumberFee[
              i
            ].techniquePayMoney; // 收费金额
            this.getCreatData[i].techniquePayRatio = ""; // 收费比例
          }
        } else {
          for (let i in this.getCreatData) {
            this.getCreatData[
              i
            ].techniquePayType = this.seachData.techniquePayType;
            this.getCreatData[i].techniquePayMoney = "";
            this.getCreatData[
              i
            ].techniquePayRatio = this.seachData.techniquePayRatio[
              i
            ].techniquePayRatio;
          }
        }
        // 课程收费
        if (this.seachData.coursePayType == 30) {
          for (let i in this.getCreatData) {
            this.getCreatData[i].coursePayType = this.seachData.coursePayType; // 收费方式
            this.getCreatData[i].coursePayMoney =
              this.seachData.curriculumChargeNumberFee[i].coursePayMoney || ""; // 收费金额
            this.getCreatData[i].coursePayRatio = ""; // 收费比例
          }
        } else {
          for (let i in this.getCreatData) {
            this.getCreatData[i].coursePayType = this.seachData.coursePayType;
            this.getCreatData[i].coursePayMoney = "";
            this.getCreatData[i].coursePayRatio = this.seachData.coursePayRatio[
              i
            ].coursePayRatio;
          }
        }
      }
      parmar.projects = this.getCreatData;
      if (this.seachData.Settlementstandardmode == "10") {
        if (this.seachData.techniquePayType == "30") {
          for (var i = 0; i < this.getCreatData.length; i++) {
            if (
              !this.getCreatData[i].techniquePayMoney ||
              this.getCreatData[i].techniquePayMoney == ""
            ) {
              this.$message({
                message: "请输入平台技术服务费每人收费金额",
                type: "warning",
              });
              return false;
            }
          }
        }
        if (this.seachData.coursePayType == "30") {
          for (var i = 0; i < this.getCreatData.length; i++) {
            if (
              !this.getCreatData[i].coursePayMoney ||
              this.getCreatData[i].coursePayMoney == ""
            ) {
              this.$message({
                message: "请输入平台课程服务费每人收费金额",
                type: "warning",
              });
              return false;
            }
          }
        }
      } else {
        if (this.seachData.platformPayType == "30") {
          for (var i = 0; i < this.getCreatData.length; i++) {
            if (
              !this.getCreatData[i].platformPayMoney ||
              this.getCreatData[i].platformPayMoney == ""
            ) {
              this.$message({
                message: "请输入平台收费金额",
                type: "warning",
              });
              return false;
            }
          }
        }
      }

      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$post(
            this.$route.query.billId && this.$route.query.stu == "edit"
              ? "/biz/settlement/bill/modify"
              : this.$route.query.billId && this.$route.query.stu == "change"
              ? "/biz/settlement/bill/change"
              : "/biz/settlement/bill/insert",
            parmar
          )
            .then((res) => {
              this.$message({
                message: res.message,
                type: "success",
              });
              this.$router.push({
                path: "/web/evaluate/statementManagementY",
                query: {
                  refresh: true,
                },
              });
            })
            .catch((err) => {
              return;
            });
        }
      });
    },
    /* 预览生成结算单 */
    watchSee(formName) {
      let parmar = {
        settlementPattern: this.seachData.Settlementstandardmode,
        serialNumberType: this.seachData.serialNumberType,
        charges: this.seachData.charges, //收费标准描述
        compId:
          this.$route.query.compId ||
          JSON.parse(this.$route.query.infos).compId,
        payeeAccount: this.seachData.payeeAccount, //收款单位-银行账号
        payeeBank: this.seachData.payeeBank, //收款单位-开户银行
        payeeName: this.seachData.payeeName, //收款单位名称
        payerName: this.seachData.payerName, // 付款单位名称
        isPublicClassPay: this.seachData.otherSettings.includes("0"),
        isShowEightyPercentage: this.seachData.otherSettings.includes("1"),
        year: this.seachData.serialNumberYear,
        billNum: this.seachData.serialNumberNum,
        seq: this.seachData.serialbillSeq,
      };
      if (this.$route.query.billId) {
        parmar.billId = this.$route.query.billId;
      }
      // 平台合并收费
      if (this.seachData.Settlementstandardmode == "20") {
        if (this.seachData.platformPayType == 30) {
          for (let i in this.getCreatData) {
            this.getCreatData[
              i
            ].platformPayType = this.seachData.platformPayType; // 收费方式
            this.getCreatData[i].platformPayMoney =
              this.seachData.platformPayChargeNumberFee[i].platformPayMoney ||
              ""; // 收费金额
            this.getCreatData[i].platformPayRatio = ""; // 收费比例
          }
        } else {
          for (let i in this.getCreatData) {
            this.getCreatData[
              i
            ].platformPayType = this.seachData.platformPayType;
            this.getCreatData[i].platformPayMoney = "";
            this.getCreatData[
              i
            ].platformPayRatio = this.seachData.platformPayRatio[
              i
            ].platformPayRatio;
          }
        }
      } else {
        // 技术收费
        if (this.seachData.techniquePayType == 30) {
          for (let i in this.getCreatData) {
            this.getCreatData[
              i
            ].techniquePayType = this.seachData.techniquePayType; // 收费方式
            this.getCreatData[
              i
            ].techniquePayMoney = this.seachData.technologyChargeNumberFee[
              i
            ].techniquePayMoney; // 收费金额
            this.getCreatData[i].techniquePayRatio = ""; // 收费比例
          }
        } else {
          for (let i in this.getCreatData) {
            this.getCreatData[
              i
            ].techniquePayType = this.seachData.techniquePayType;
            this.getCreatData[i].techniquePayMoney = "";
            this.getCreatData[
              i
            ].techniquePayRatio = this.seachData.techniquePayRatio[
              i
            ].techniquePayRatio;
          }
        }
        // 课程收费
        if (this.seachData.coursePayType == 30) {
          for (let i in this.getCreatData) {
            this.getCreatData[i].coursePayType = this.seachData.coursePayType; // 收费方式
            this.getCreatData[i].coursePayMoney =
              this.seachData.curriculumChargeNumberFee[i].coursePayMoney || ""; // 收费金额
            this.getCreatData[i].coursePayRatio = ""; // 收费比例
          }
        } else {
          for (let i in this.getCreatData) {
            this.getCreatData[i].coursePayType = this.seachData.coursePayType;
            this.getCreatData[i].coursePayMoney = "";
            this.getCreatData[i].coursePayRatio = this.seachData.coursePayRatio[
              i
            ].coursePayRatio;
          }
        }
      }
      this.getCreatData.filter((item) => {
        delete item.createTime;
        delete item.updateTime;
        delete item.endDate;
      });
      parmar.projects = this.getCreatData;
      if (this.seachData.techniquePayType == "30") {
        for (var i = 0; i < this.getCreatData.length; i++) {
          if (
            !this.getCreatData[i].techniquePayMoney ||
            this.getCreatData[i].techniquePayMoney == ""
          ) {
            this.$message({
              message: "请输入平台技术服务费每人收费金额",
              type: "warning",
            });
            return false;
          }
        }
      }
      if (this.seachData.coursePayType == "30") {
        for (var i = 0; i < this.getCreatData.length; i++) {
          if (
            !this.getCreatData[i].coursePayMoney ||
            this.getCreatData[i].coursePayMoney == ""
          ) {
            this.$message({
              message: "请输入平台课程服务费每人收费金额",
              type: "warning",
            });
            return false;
          }
        }
      }
      parmar.projects = this.getCreatData;
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$post(
            this.$route.query.billId && this.$route.query.stu == "edit"
              ? "/biz/settlement/bill/update/preview"
              : this.$route.query.billId && this.$route.query.stu == "change"
              ? "/biz/settlement/bill/change/preview"
              : "/biz/settlement/bill/insert/preview",

            parmar
          )
            .then((res) => {
              this.dialogVisible1 = true;
              this.lookhtTitle = "预览结算单";
              this.$nextTick(() => {
                pdf.embed(res.data, "#pdf-cert");
              });
            })
            .catch((err) => {
              return;
            });
        } else {
          return false;
        }
      });
    },
    dblclick(row, column, cell, event) {
      switch (column.label) {
        case "补贴标准（元）":
          row.readonly = false;
          break;
      }
    },
    dblclick0(row, column, cell, event) {
      switch (column.label) {
        case "每人收费金额":
          row.readonly = false;
          break;
        case "收费比例(%)":
          row.readonly = false;
          break;
      }
    },
    dblclick1(row, column, cell, event) {
      switch (column.label) {
        case "每人收费金额":
          row.readonly = false;
          break;
        case "收费比例(%)":
          row.readonly = false;
          break;
      }
    },
    dblclick2(row, column, cell, event) {
      switch (column.label) {
        case "每人收费金额":
          row.readonly = false;
          break;
        case "收费比例(%)":
          row.readonly = false;
          break;
      }
    },
    blur(row, index) {
      row.readonly = true;
    },
    changes(index, val) {
      this.$refs.singleTable.setCurrentRow(
        this.seachData.subsidyStandard[index]
      );
    },
  },
};
</script>

<style lang="less" scoped>
.searchList {
  padding: 0 !important;
  .listLabel {
    margin-left: 10px;
  }
  .listInput {
    width: 90%;
  }
}
.framePage-body {
  position: relative;
  padding: 20px;
  h2 {
    margin-bottom: 20px;
  }
  h3 {
    float: left;
    line-height: 38px;
    margin-right: 40px;
  }
  .shell {
    float: left;
  }
  .el-form-item {
    width: 600px;
    margin-bottom: 15px;
    // .tb-edit .el-input {
    //   display: none;
    // }

    // .tb-edit .current-row .el-input {
    //   display: block;
    // }

    // .tb-edit .current-row .el-input + span {
    //   display: none;
    // }
    .szwidth {
      // width: 200px;
      margin-right: 10px;
    }
  }
  .serialNumberType {
    width: 60rem;
  }
  .operations_1 {
    position: absolute;
    right: 0;
    top: 20px;
    .el-button {
      margin-right: 20px;
    }
    .el-select {
      width: 150px;
      float: left;
      margin-right: 20px;
    }
  }
  .operations_2 {
    .el-button {
      padding: 10px 20px;
    }
  }
}
.generateStatementAccount
  .framePage-body
  .operations_1
  /deep/
  .el-select
  .el-input__icon {
  line-height: 33px !important;
}
.generateStatementAccount .operations_2 /deep/ .el-form-item__content {
  text-align: center !important;
}
.generateStatementAccount /deep/ .el-input__inner {
  height: 32px;
  line-height: 32px;
}
.generateStatementAccount /deep/ .el-textarea__inner {
  height: 200px;
}
.generateStatementAccount /deep/ .el-table th {
  padding: 0 !important;
}
.generateStatementAccount /deep/ .el-divider {
  clear: both;
}
.generateStatementAccount
  /deep/
  .el-table--enable-row-transition
  .el-table__body
  td {
  padding: 0;
}
.generateStatementAccount /deep/ .el-dialog__body {
  overflow: hidden;
  .block {
    width: 100%;
    .el-image {
      width: 48% !important;
      float: left;
      margin-bottom: 8px;
      margin-left: 1.5%;
    }
  }
}
.tb-edit /deep/ .el-input__inner {
  border: none;
}
.el-table /deep/ .cell {
  padding: 0;
}
</style>
